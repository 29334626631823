import React from "react"
import Helmet from "react-helmet"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import CtaDisclosure from "@tightrope/ctadisclosure"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"
import Styles from "./horoscopehp.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import PrdBestSignsData from "./data/data.json"

class PrdBestSigns extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      data: PrdBestSignsData
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
  }

  render()
{
    return (
      <>
      <Helmet>
       <style type="text/css">{`

      #ctadisclosurenewtab-module--ctadisclaimer {
        padding: 20px 27%;
        color: #f1f1f1;
        font-family: 'Open Sans', sans-serif;
      }

      .horoscopehp-module--horoscopehp span.horoscopehp-module--pt {
        font-family: "Montserrat", sans-serif;
      }

      .footerlinkssingle-module--br {
        margin: 0 auto;
        background-color: #f1f1f1;
        padding: 15px;
        font-family: 'Lora', serif;
        font-size: 10pt;
      }

      `}
      </style>
      <title>Free Horoscopes - horoscopeoftheday.com</title></Helmet>
      <div className = {Styles.horoscopehp}>
      <div className = {Styles.header}>
      <img className = {Styles.toplogo} src={this.state.data.logo}/>

        	<span className={Styles.pt}>{this.state.data.productType}
        </span>
        	</div>
        	<div className = {Styles.clear}></div>

        <div className = {Styles.outer}>

        <section className = {Styles.topZodiacs}>
        <div className = {Styles.top}>
        <h1>{this.state.data.headline}</h1>
        <h3 className={Styles.subhead}>{this.state.data.subhead}</h3>
        <div className = {Styles.zgrid}>
        <div className= {Styles.gridItem}><img src="/img/aries.svg" id="aries-fr"/>
                    <p className= {Styles.zname}>Aries</p>
                      <p className= {Styles.zodiacDates}>Mar 21 - Apr 19</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/taurus.svg" id="taurus"/>
                      <p className= {Styles.zname}>Taurus</p>
                      <p className= {Styles.zodiacDates}>Apr 20 - May 20</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/gemini.svg" id="gemini"/>
                      <p className= {Styles.zname}>Gemini</p>
                      <p className= {Styles.zodiacDates}>May 21 - Jun 20</p>
                    </div>
                   <div className= {Styles.gridItem}><img src="/img/cancer.svg" id="cancer"/>
                      <p className= {Styles.zname}>Cancer</p>
                      <p className= {Styles.zodiacDates}>Jun 21 - Jul 22</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/leo.svg" id="leo"/>
                      <p className= {Styles.zname}>Leo</p>
                      <p className= {Styles.zodiacDates}>Jul 23 - Aug 22</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/virgo.svg" id="virgo"/>
                      <p className= {Styles.zname}>Virgo</p>
                      <p className= {Styles.zodiacDates}>Aug 23 - Sep 22</p>
                    </div>
                   <div className= {Styles.gridItem}><img src="/img/libra.svg" id="libra"/>
                      <p className= {Styles.zname}>Libra</p>
                      <p className= {Styles.zodiacDates}>Sep 23 - Oct 22</p>
                    </div>
                   <div className= {Styles.gridItem}><img src="/img/scorpio.svg" id="scorpio"/>
                      <p className= {Styles.zname}>Scorpio</p>
                      <p className= {Styles.zodiacDates}>Oct 23 - Nov 21</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/sagittarius.svg" id="sagittarius"/>
                      <p className= {Styles.zname}>Sagittarius</p>
                      <p className= {Styles.zodiacDates}>Nov 22 - Dec 21</p>
                    </div>
                    <div className= {Styles.gridItem}><img src="/img/capricorn.svg" id="capricorn"/>
                      <p className= {Styles.zname}>Capricorn</p>
                      <p className= {Styles.zodiacDates}>Dec 22 - Jan 19</p>
                    </div>
                   <div className= {Styles.gridItem}><img src="/img/aquarius.svg" id="aquarius"/>
                      <p className= {Styles.zname}>Aquarius</p>
                      <p className= {Styles.zodiacDates}>Jan 20 - Feb 18</p>
                    </div>
                   <div className= {Styles.gridItem}><img src="/img/pisces.svg" id="pisces"/>
                      <p className= {Styles.zname}>Pisces</p>
                      <p className= {Styles.zodiacDates}>Feb 19 - Mar 20</p>
                    </div>
                  </div>
                  <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
      			{isFirefox() ? <CtaDisclosure data={this.state.data}></CtaDisclosure> : <CtaDisclosureNewtab  data={this.state.data}></CtaDisclosureNewtab> }

                </div>
        </section>
        <section>
          <div className = {Styles.middle}>
            <h2>Learn More About Your Future with Personalized Horoscopes</h2>
            <div className = {Styles.midgrid}>
              <img src="/img/daily.jpg" className={Styles.time}/>
              <img src="/img/weekly.jpg" className={Styles.time}/>
              <img src="/img/monthly.jpg" className={Styles.time}/>
            </div>
            <div className={Styles.compliance}>{this.state.data.productType}</div>
  <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
          </div>
        </section>
        <section>
          <div className={Styles.bottom}>
            <h2>Learn More with Daily Predictions on:</h2>
            <div className = {Styles.bottomGrid}>
              <div className = {Styles.listItem}>
              <div className = {Styles.item}>
                <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Love</div>
              </div>
              <div className = {Styles.item}>
                <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Compatibility</div>
              </div>
              <div className = {Styles.item}>
                <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Name Meanings</div>
              </div>
            </div>
              <div className = {Styles.listItem}>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Health</div>
                </div>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Career</div>
                </div>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Money</div>
                </div></div>
              <div className = {Styles.listItem}>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Tarot</div>
                </div>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Chinese Horoscope</div>
                </div>
                <div className = {Styles.item}>
                  <img src="/img/white-ico.png" className={Styles.whiteico}></img><div>Moon Calendar</div>
                </div>
              </div>

            </div>
            <div className={Styles.compliance}>{this.state.data.productType}</div>
              <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
          </div>
        </section>
        <FooterlinksSingle></FooterlinksSingle>
          </div>
            </div>
</>
    )
  }
}
export default PrdBestSigns
